import React, { useState } from "react"
import _ from "underscore"
import he from "he"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import Img from "gatsby-image"
import BackLink from "../components/utils/backlink"
import IconText from "../components/utils/icontext"
import Timing from "../components/events/timing"
import Gallery from "../components/utils/gallery"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"

const EventTemplate = ({ pageContext }) => {
  const maxNumberOfDates = 3
  let dates = []

  const { data } = pageContext
  const { featured_media, acf } = data

  if (acf.date.multiple_dates) {
    dates = acf.date.dates
      .filter((date) => {
        const end = moment(date.to)
        return end.endOf("day").isAfter()
      })
      .sort((a, b) => {
        if (a.to > b.to) return 1

        if (a.to < b.to) return -1

        return 0
      })
  }

  const [showsAllDates, showAllDates] = useState(
    maxNumberOfDates > dates.length
  )

  const addressFields = _.omit(acf.location, _.isEmpty)

  let address = _.chain(addressFields).omit(["zip", "city"]).toArray().value()

  address.push(
    _.chain(addressFields).pick(["zip", "city"]).toArray().value().join(" ")
  )

  let directionsLink = false

  if (acf.location.address && acf.location.zip && acf.location.city)
    directionsLink =
      `https://www.google.com/maps/dir/?api=1&destination=` +
      encodeURIComponent(
        `${acf.location.address}, ${acf.location.zip} ${acf.location.city}`
      )

  return (
    <Layout mainClassName="pb-0">
      {data && (
        <>
          <SEO
            title={he.decode(data.title)}
            description={he.decode(data.excerpt)}
          />
          <article>
            {featured_media && featured_media.localFile && (
              <div className="px-0 -mb-6 lg:max-h-screen-1/3 lg:overflow-hidden">
                <Img
                  fluid={featured_media.localFile.childImageSharp.fluid}
                  alt={featured_media.alt_text}
                  title={featured_media.title}
                />
              </div>
            )}

            <div className="relative z-10 min-h-screen pb-24 bg-white py-sm mt-sm rounded-t-xl">
              <div className="container narrow">
                <header className="pb-4 mt-4 mb-8 text-base border-b">
                  <BackLink to="/events" />
                  <h1
                    className="mt-2"
                    dangerouslySetInnerHTML={{ __html: data.title }}
                  />
                  {acf.excerpt && (
                    <p className="text-lg font-bold text-gray">{acf.excerpt}</p>
                  )}

                  <IconText icon="calendar" extra={acf.date.text}>
                    {!acf.date.multiple_dates && <Timing event={data} />}
                    {acf.date.multiple_dates &&
                      dates.map((date, i) => {
                        if (!showsAllDates && i > maxNumberOfDates - 1)
                          return <></>

                        return (
                          <div key={`${data.id}-${i}`}>
                            <Timing
                              date={{
                                ...date,
                                whole_day: acf.date.whole_day,
                              }}
                            />
                          </div>
                        )
                      })}
                    {!showsAllDates && (
                      <button
                        type="button"
                        className="text-primary hover:underline"
                        onClick={() => showAllDates(true)}
                      >
                        Weitere Termine anzeigen
                      </button>
                    )}
                  </IconText>

                  {acf.cost && (
                    <IconText
                      icon="wallet"
                      extra={acf.cost_text}
                      className="-mt-2"
                    >
                      {acf.cost}
                    </IconText>
                  )}

                  <IconText icon="map-marker" className="-mt-2">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: address.join("<br />"),
                      }}
                    />
                    {directionsLink && (
                      <a
                        href={directionsLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Route mit Google Maps
                        <FontAwesomeIcon
                          icon={["fal", "external-link"]}
                          className="ml-2"
                        />
                      </a>
                    )}
                  </IconText>
                </header>

                {acf.pictures && (
                  <div className="-mx-sm">
                    <Gallery pictures={acf.pictures} />
                  </div>
                )}

                <div
                  className="article"
                  dangerouslySetInnerHTML={{ __html: data.content }}
                />
              </div>
            </div>
          </article>
        </>
      )}
    </Layout>
  )
}

export default EventTemplate
