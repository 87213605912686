import React from "react"
import moment from "moment"
import "moment/locale/de";

class Timing extends React.Component {
    from_to(timeOnly) {
        const { from, to, whole_day } = this.props.date || this.props.event.acf.date

        const start = moment(from)
        const end = moment(to)
        let timing

        if (whole_day) {

            if (start.isSame(end, "day"))
                timing = !timeOnly ? `${start.format('L')}` : false
            else if (start.isSame(end, "year"))
                timing = `${start.format('DD.MM.')} – ${end.format('L')}`
            else
                timing = `${start.format('L')} – ${end.format('L')}`

        } else {

            if (start.isSame(end, "minute"))
                timing = timeOnly ? `${start.format('LT')} Uhr` : `${start.format('L LT')} Uhr`
            else if (start.isSame(end, "day"))
                timing = timeOnly ? `${start.format('LT')} – ${end.format('LT')} Uhr` : `${start.format('L LT')} – ${end.format('LT')} Uhr`
            else if (start.isSame(end, "year"))
                timing = `${start.format('DD.MM. LT')} Uhr – ${end.format('DD.MM.YYYY LT')} Uhr`
            else
                timing = `${start.format('L LT')} Uhr – ${end.format('L LT')} Uhr`

        }

        return timing
    }

    render() {
        const {timeOnly, ...other } = this.props
        
        return (
            <>
                <time {...other}>{ this.from_to(timeOnly) }</time>
            </>
        )
    }
}

export default Timing
